<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: Reports.vue
Description:This File Contains the functionality of the reports of the particular pond i.e to the downloaded or send the email
-->
<template>
  <layout-pondlogs container-type="card-footer" class="reports">
    <template slot="title">
      <div style="display: flex; align-items: center">
        <i class="material-icons-round">pie_chart</i>
        <div>{{ $t("Comn_reports") }}</div>
      </div>
    </template>
    <template slot="header">
      <er-date-picker
        v-model="value1"
        class="align-item-left"
        :timeZoneString="getUserTimeZoneString"
        value-format="yyyy-MM-dd"
        :format="this.upm__getFormatDateString"
        type="daterange"
        range-separator="To"
        start-placeholder="Start date"
        end-placeholder="End date"
        @change="handelDateChange"
      ></er-date-picker>
    </template>
    <template slot="layout-pondlogs-scrollable-main">
      <el-row type="flex" justify="space-between">
        <el-col :span="16" class="pond-container">
          <div>
            <div class="header">
              <span> {{ $t("Comn_ponds") }} </span>
              <span>
                <el-button-group>
                  <el-button
                    @click="handlePondStatusButton('active')"
                    :class="{ active: selectedPondStatus === 'active' }"
                    size="mini"
                    type="pain"
                    >{{ $t("Comn_active") }}</el-button
                  >
                  <el-button
                    @click="handlePondStatusButton('inactive')"
                    :class="{ active: selectedPondStatus === 'inactive' }"
                    size="mini"
                    type="plain"
                    >{{ $t("Comn_inactive") }}</el-button
                  >
                  <el-button
                    @click="handlePondStatusButton('all')"
                    :class="{ active: selectedPondStatus === 'all' }"
                    size="mini"
                    type="plain"
                    >{{ $t("Comn_all") }}</el-button
                  >
                </el-button-group>
              </span>
            </div>
            <div v-if="pondListData.length > 0" v-loading="loading">
              <el-checkbox
                v-model="checkAllPonds"
                @change="handleCheckAllPonds"
              >
                <span class="title">{{ $t("Comn_all") }}</span>
              </el-checkbox>
              <div style="margin: 15px 0"></div>

              <el-checkbox-group
                v-model="pondList"
                style="
                  display: grid;
                  grid-template-columns: auto auto auto;
                  grid-gap: 10px;
                "
              >
                <el-checkbox
                  v-for="pond in allPondsofUser"
                  :label="pond._id"
                  :key="pond._id"
                >
                  <span class="title">{{ pond.title }}</span>
                </el-checkbox>
              </el-checkbox-group>
            </div>
            <div v-else class="no-data">
              {{ $t("Comn_no_data") }}
            </div>
          </div>
        </el-col>
        <el-divider direction="vertical"></el-divider>
        <el-col :span="8" class="report-container">
          <div class="report-container__one">
            <div class="header">{{ $t("device_generated_data") }}</div>
            <el-checkbox
              v-model="deviceCheckAllReports"
              @change="handleCheckAllReport($event, 'device')"
            >
              <span class="title">{{ $t("Comn_all") }}</span>
            </el-checkbox>
            <div style="margin: 15px 0"></div>
            <el-checkbox-group
              v-model="checkList"
              style="display: grid; grid-gap: 10px"
            >
              <el-checkbox
                v-for="pond in deviceGeneratedReportsList"
                :label="pond.id"
                :key="pond.id"
              >
                <span class="title">{{ pond.title }}</span>
              </el-checkbox>
            </el-checkbox-group>
            <p class="report-container__one__help-text">
              <el-alert type="info" :closable="false">
                <i class="el-icon-info"></i>
                {{ $t("date_range_30_days", { days: deviceDateRangeCustMsg + 1 }) }}
              </el-alert>
            </p>
          </div>
          <div class="border-bottom" style="margin: 15px 0"></div>
          <div class="report-container__two">
            <div class="header">{{ $t("culture_data") }}</div>
            <el-checkbox
              v-model="cultureCheckAllReports"
              @change="handleCheckAllReport($event, 'culture')"
            >
              <span class="title">{{ $t("Comn_all") }}</span>
            </el-checkbox>
            <div style="margin: 15px 0"></div>
            <el-checkbox-group
              v-model="checkList"
              style="display: grid; grid-gap: 10px"
            >
              <el-checkbox
                v-for="pond in cultureGeneratedReportsList"
                :label="pond.id"
                :key="pond.id"
              >
                <span class="title">{{ pond.title }}</span>
              </el-checkbox>
            </el-checkbox-group>
            <p class="report-container__two__help-text">
              <el-alert type="info" :closable="false">
                <i class="el-icon-info"></i>
                {{
                  $t("date_range_30_days", {
                    days: maxAllowedDateRange.culture.singlePond + 1
                  })
                }}
              </el-alert>
            </p>
          </div>
        </el-col>
      </el-row>
      <el-divider direction="horizontal"></el-divider>
    </template>
    <template slot="card-footer">
      <el-col :span="24">
        <div class="footer-container">
          <div class="info__container">
            <i class="material-icons-outlined">info</i>
            <p class="content">{{ $t("Comn_reports_footer") }}</p>
          </div>
          <div class="download__container">
            <div class="gap__between__download__send">
              <er-button
                :loading="downloadLoading"
                btnType="download"
                @click="download"
                :showLabel="true"
                :showIcon="true"
              >
                {{ $t("Comn_download_report") }}
              </er-button>
            </div>

            <div class="email-section">
              <er-button
                :loading="emailLoading"
                btnType="email"
                :showLabel="true"
                :showIcon="true"
                @click="sendemail"
                :disabled="!emailAllowed"
              >
                {{ $t("Comn_send_email") }}
              </er-button>
            </div>
          </div>
        </div>
      </el-col>
    </template>
  </layout-pondlogs>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import userService from "./../../services/userService";
import notificationMixin from "@/mixins/notificationMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import { handleStoreRegistration } from "@/utils/storeRegisterHandlerUtils";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
export default {
  mixins: [
    notificationMixin,
    datesHandlerMixin,
    userPreferenceMixin,
    errorHandlerMixin
  ],
  data() {
    return {
      emailAllowed: true,
      checkList: [],
      pondList: [],
      value1: "",
      loading: false,
      downloadLoading: false,
      emailLoading: false,
      checkAllReports: false,
      selectedPondStatus: "active",
      checkAllPonds: false,
      isDateRangeValid: true,
      deviceGeneratedReportListData: [
        "POND_GUARD_DATA",
        "POND_MOTHER_DATA",
        "HOURLY_FEED_DATA",
        "INTAKE_RESPONSE_DATA"
      ],
      cultureGeneratedReportListData: [
        "MANUAL_FEED_DATA",
        "WATER_QUALITY_TEST_DATA",
        "MEDICINES_MINERALS_DATA",
        "YIELD_DATA",
        "ABW_DATA"
      ],
      maxAllowedDateRange: {
        deviceGenerated: {
          singlePond: 29,
          multiplePonds: 6
        },
        culture: {
          singlePond: 89, // 90 days taking considering selected dates. So we are getting +1 day extra So mentioned 89, 29, 6
          multiplePonds: 89 // Culture data doesn't seem to change for multiple ponds
        }
      },
      deviceDateRangeCustMsg: 29,
      deviceCheckAllReports: false,
      cultureCheckAllReports: false,
      deviceDateRangeMessage: "",
      cultureDateRangeMessage: ""
    };
  },
  computed: {
    ...mapGetters("pond", {
      getPonds: "getPonds"
    }),
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation",
        getUserProfile: "getUserProfile"
    }),
    chm__availableInterval() {
      return {
        start: this.dhm__dateUtilsLib.parse(
          this.getCurrentCulture.start_date,
          this.dhm__dateUtilsLib.isoFormatString,
          new Date()
        ),
        end: this.dhm__dateUtilsLib.parse(
          this.getCurrentCulture.end_date,
          this.dhm__dateUtilsLib.isoFormatString,
          new Date()
        )
      };
    },
    allPondsofUser() {
      return this.$lodash
        .cloneDeep(this.getPonds)
        .sort((a, b) =>
          this.$commonUtils.alphaNumericComparator(a.title, b.title)
        );
    },
    pondListData: function() {
      return this.$lodash.cloneDeep(this.getPonds).map((x) => x._id);
    },
    allPondsInformationList: function() {
      return [
        {
          title: this.$t("Comn_pond_guard"),
          id: "POND_GUARD_DATA",
          key: "deviceGenerated"
        },
        {
          title: this.$t("Comn_pond_mother"),
          id: "POND_MOTHER_DATA",
          key: "deviceGenerated"
        },
        {
          title: this.$t("Comn_hourly_feed"),
          id: "HOURLY_FEED_DATA",
          key: "deviceGenerated"
        },
        {
          title: this.$t("Comn_manual_feed"),
          id: "MANUAL_FEED_DATA",
          key: "cultureGenerated"
        },
        { title: "ABW", id: "ABW_DATA", key: "cultureGenerated" },
        {
          title: this.$t("LabTst_lab_tests"),
          id: "WATER_QUALITY_TEST_DATA",
          key: "cultureGenerated"
        },
        {
          title: this.$t("Comn_response_quality"),
          id: "INTAKE_RESPONSE_DATA",
          key: "deviceGenerated"
        },

        {
          title: this.$t("Comn_medicines_and_minerals"),
          id: "MEDICINES_MINERALS_DATA",
          key: "cultureGenerated"
        },
        {
          title: this.$t("Comn_yield"),
          id: "YIELD_DATA",
          key: "cultureGenerated"
        }
      ];
    },
    deviceGeneratedReportsList() {
      return this.allPondsInformationList.filter(
        (item) => item.key === "deviceGenerated"
      );
    },
    cultureGeneratedReportsList() {
      return this.allPondsInformationList.filter(
        (item) => item.key === "cultureGenerated"
      );
    },
    deviceReports() {
      return this.checkList.filter(
        (reportId) =>
          this.allPondsInformationList.find((r) => r.id === reportId).key ===
          "deviceGenerated"
      );
    },
    cultureReports() {
      return this.checkList.filter(
        (reportId) =>
          this.allPondsInformationList.find((r) => r.id === reportId).key ===
          "cultureGenerated"
      );
    },
    getDateObjArrOfCultureDateRange() {
      const start_date = this.dhm__dateUtilsLib.utcToZonedTime(
        this.getCultureDateRange[0],
        this.getUserTimeZoneString
      );
      const end_date = this.dhm__dateUtilsLib.utcToZonedTime(
        this.getCultureDateRange[1],
        this.getUserTimeZoneString
      );
      return [start_date, end_date];
    }
  },
  watch: {
    checkList: function(newVal) {
      this.handelDateChange(this.value1);
    },
    pondList: function(newVal) {
      this.handelDateChange(this.value1);
      if (this.pondList.length > 1) {
        this.deviceDateRangeCustMsg = 6;
      } else {
        this.deviceDateRangeCustMsg = 29;
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    handleStoreRegistration(to, from);
    next();
  },
  async mounted() {
    // this.value1 = this.getDefaultDateRange();
    if (!this.getUserProfile.email) {
          this.emailAllowed = false;
      }
    const today = this.dhm__getTodayInUserTZ;
    const defaultEndDate = today;
    const maxDaysAllowed = this.calculateMaxDaysAllowed(6); // Get the initial maximum limit
    const defaultStartDate = this.dhm__dateUtilsLib.subtract(defaultEndDate, {
      days: maxDaysAllowed
    });

    this.value1 = [
      this.dhm__formatTZ(defaultStartDate, "yyyy-MM-dd"),
      this.dhm__formatTZ(defaultEndDate, "yyyy-MM-dd")
    ];

    await this.initComponent();
  },
  methods: {
    ...mapActions("pond", {
      fetchAllPonds: "fetchAllPonds"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    disableDateMethod: function(time) {
      const currDate = time;

      // Logic for Culture Data restriction
      // const paramObjCulture = {
      //   dateRange: this.getDateObjArrOfCultureDateRange,
      //   date: currDate,
      //   timeZone: this.getUserTimeZoneString,
      //   actionsOnDate: [],
      //   actionsOnDateRangeItem: []
      // };
      // const isWithinCultureRange = this.dhm__dateUtilsLib.queryInDateRange(
      //   paramObjCulture
      // );

      // Logic for 90-day restriction
      const today = this.dhm__getTodayInUserTZ;
      const ninetyDaysAgo = this.dhm__dateUtilsLib.subtract(today, {
        days: 89
      });
      const isWithin90Days = currDate >= ninetyDaysAgo;

      // Combine the restrictions
      return !isWithin90Days;
    },
    calculateMaxDaysAllowed(customDays = 89) {
      const numSelectedPonds = this.pondList?.length;
      const selectedReports = this.checkList;

      const hasDeviceReports = selectedReports.some(
        (report) =>
          this.allPondsInformationList.find((r) => r.id === report).key ===
          "deviceGenerated"
      );

      const hasCultureReports = selectedReports.some(
        (report) =>
          this.allPondsInformationList.find((r) => r.id === report).key ===
          "cultureGenerated"
      );

      let maxDaysAllowed = null;

      if (hasDeviceReports) {
        maxDaysAllowed =
          numSelectedPonds === 1
            ? this.maxAllowedDateRange.deviceGenerated.singlePond
            : this.maxAllowedDateRange.deviceGenerated.multiplePonds;
      } else if (hasCultureReports) {
        maxDaysAllowed = this.maxAllowedDateRange.culture.singlePond; // Assumed to always be 90
      } else {
        maxDaysAllowed = customDays;
      }

      return maxDaysAllowed;
    },
    getDefaultDateRange() {
      return [
        this.dhm__formatTZ(
          this.dhm__dateUtilsLib.subtract(this.dhm__getTodayInUserTZ, {
            days: ""
          }),
          "yyyy-MM-dd"
        ),
        this.dhm__formatTZ(this.dhm__getTodayInUserTZ, "yyyy-MM-dd")
      ];
    },
    getValidDateQueryType(date1, date2, format) {
      // const today = this.dhm__dateUtilsLib.startOfDay(
      //   this.dhm__getTodayInUserTZ
      // );
      const date1ISO = this.dhm__dateUtilsLib.utcToZonedTime(
        this.dhm__castUserUTCToUTCISO(new Date(date1)),
        this.getUserTimeZoneString
      );
      const date2ISO = this.dhm__dateUtilsLib.utcToZonedTime(
        this.dhm__castUserUTCToUTCISO(new Date(date2)),
        this.getUserTimeZoneString
      );
      // calculate difference in days of date1ISO, date2ISO
      const diffInDays = this.dhm__dateUtilsLib.differenceInDays(
        date2ISO,
        date1ISO
      );
      console.log("diffInDays", diffInDays);
      // if (diffInDays <= 2) {
      //   return true;
      // } else {
      //   return false;
      // }
      return true;
    },
    // handelDateChange(date) {
    //   try {
    //     this.isDateRangeValid = this.getValidDateQueryType(
    //       ...date,
    //       "YYYY-MM-DD"
    //     );
    //     console.log("isDateRangeValid", this.isDateRangeValid);
    //     if (this.isDateRangeValid) {
    //       // to do
    //     } else {
    //       this.value1 = this.getDefaultDateRange();
    //       throw {
    //         type: "FAIL_TO_SAVE",
    //         errors: [
    //           {
    //             message: this.$t("Comn_difference_in_date_should_be_3")
    //           }
    //         ]
    //       };
    //     }
    //   } catch (error) {
    //     this.ehm__errorMessages(error);
    //   }
    //   this.mixPanelEventGenerator({ eventName: "Reports - Date Filter" });
    // },
    updateDateValidationMessages(maxDaysAllowed) {
      const selectedReports = this.checkList;

      const hasDeviceReports = selectedReports.some(
        (report) =>
          this.allPondsInformationList.find((r) => r.id === report).key ===
          "deviceGenerated"
      );
      const hasCultureReports = selectedReports.some(
        (report) =>
          this.allPondsInformationList.find((r) => r.id === report).key ===
          "cultureGenerated"
      );

      if (hasDeviceReports) {
        this.deviceDateRangeMessage = this.$t("date_range_30_days", {
          days: maxDaysAllowed + 1
        });
      } else {
        this.deviceDateRangeMessage = ""; // Clear if only culture reports are selected
      }

      // Similar logic to update cultureDateRangeMessage if applicable:
      if (hasCultureReports) {
        this.cultureDateRangeMessage = this.$t("date_range_30_days", {
          days: maxDaysAllowed + 1
        });
      } else {
        this.cultureDateRangeMessage = "";
      }
    },

    adjustDateRange(maxDaysAllowed) {
      const today = this.dhm__getTodayInUserTZ;
      const adjustedEndDate = today;
      const adjustedStartDate = this.dhm__dateUtilsLib.subtract(
        adjustedEndDate,
        { days: maxDaysAllowed }
      );

      this.value1 = [
        this.dhm__formatTZ(adjustedStartDate, "yyyy-MM-dd"),
        this.dhm__formatTZ(adjustedEndDate, "yyyy-MM-dd")
      ];

      // Notification based on report types
      const hasDeviceReports = this.deviceReports.length > 0;
      const hasCultureReports = this.cultureReports.length > 0;

      let notificationMessage = this.$t("date_range_30_days", {
        days: this.maxAllowedDateRange.culture.singlePond + 1
      }); // Default title

      if (hasDeviceReports) {
        notificationMessage = this.deviceDateRangeMessage;
      } else if (hasCultureReports) {
        notificationMessage = this.cultureDateRangeMessage;
      }
      // this.shouldItDownload = false;
      throw {
        type: "FAIL_TO_SAVE",
        errors: [
          {
            message: notificationMessage
          }
        ]
      };
    },
    handelDateChange(date) {
      try {
        const date1ISO = this.dhm__dateUtilsLib.utcToZonedTime(
          this.dhm__castUserUTCToUTCISO(new Date(date[0])),
          this.getUserTimeZoneString
        );
        const date2ISO = this.dhm__dateUtilsLib.utcToZonedTime(
          this.dhm__castUserUTCToUTCISO(new Date(date[1])),
          this.getUserTimeZoneString
        );
        const daysDifference = this.dhm__dateUtilsLib.differenceInDays(
          date2ISO,
          date1ISO
        );

        // if (daysDifference > 90) {
        //   // this.adjustDateRange(90);
        //   // this.updateDateValidationMessages(90); // We'll add this method soon
        //   throw {
        //     type: "FAIL_TO_SAVE",
        //     errors: [
        //       {
        //         message: "date range should not be grater than 90"
        //       }
        //     ]
        //   };
        // }
        const maxDaysAllowed = this.calculateMaxDaysAllowed(89);

        if (daysDifference > maxDaysAllowed) {
          this.updateDateValidationMessages(maxDaysAllowed);
          this.adjustDateRange(maxDaysAllowed);

          // Optionally display a notification to the user
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: "please select in the date range"
              }
            ]
          };
        } else {
          // Clear any previous messages (add relevant code to clear messages)
          this.deviceDateRangeMessage = "";
          this.cultureDateRangeMessage = "";
        }
      } catch (error) {
        this.ehm__errorMessages(error);
        return;
      }
      this.mixPanelEventGenerator({ eventName: "Reports - Date Filter" });
      // ... rest of your handleDateChange logic ...
    },
    handlePondStatusButton(event) {
      this.mixPanelEventGenerator({
        eventName: "Reports - " + event + " Ponds"
      });
      this.selectedPondStatus = event;
      this.initComponent();
      this.checkAllPonds = false;
      this.pondList = [];
    },
    downloadkeys() {
      var object = {
        ponderrormessage: this.$t("Comn_report_pond_error"),
        reporterrormessage: this.$t("Comn_report_report_error"),
        downloaderrormessage: this.$t("Comn_download_error")
      };
      if (this.checkList.length === 0 && this.pondList.length === 0) {
        return object.downloaderrormessage;
      } else {
        return this.pondList.length
          ? object.reporterrormessage
          : object.ponderrormessage;
      }
    },
    emailkeys() {
      var object = {
        ponderrormessage: this.$t("Comn_pond_email_error"),
        reporterrormessage: this.$t("Comn_report_email_error"),
        downloaderrormessage: this.$t("Comn_email_error")
      };
      if (this.checkList.length === 0 && this.pondList.length === 0) {
        return object.downloaderrormessage;
      } else {
        return this.pondList.length
          ? object.reporterrormessage
          : object.ponderrormessage;
      }
    },
    async initComponent() {
      this.loading = true;
      try {
        await Promise.all([
          this.fetchAllPonds({
            location_id: this.getCurrUserLocation._id,
            status:
              this.selectedPondStatus !== "all"
                ? ["ACTIVE", "INACTIVE"].find(
                    (item) => item === this.selectedPondStatus.toUpperCase()
                  )
                : ["ACTIVE", "INACTIVE"],
            include: ["title", "status"],
            get_all: true
          })
        ]);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    download: async function() {
      try {
        this.downloadLoading = true;
        this.$gblUAMIsHighPriorityUsersRestricted();
        if (!this.pondList.length || !this.checkList.length) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                type: "number",
                required: true,
                message: this.downloadkeys(),
                trigger: "blur"
              }
            ]
          };
        }
        const response = await userService.fetchZipReportsData({
          pond_ids: this.pondList,
          params: this.checkList,
          from_time: this.value1[0],
          to_time: this.value1[1]
        });

        // Convert ArrayBuffer to Blob
        const blob = new Blob([response.data], { type: "application/zip" });
        // Create a download link
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Eruvaka.zip";

        // Append the link to the document
        document.body.appendChild(link);

        // Trigger the click event to start the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);

        this.nofm__notifySuccess({
          msgLangkey: {
            title: "Comn_download_success_msg",
            count: 0
          }
        });

        this.checkListEventsLoop();
        this.mixPanelEventGenerator({ eventName: "Reports - Download Zip" });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.downloadLoading = false;
      }
    },
    ehm__error403Handler: function(err) {
    const errorData = err.response.data;
      if (errorData.error_code === "EMAIL_NOT_VERIFIED") {
        this.$notify({
          title: this.$t("failed"),
          message: this.$t("email_not_verified"),
          type: "error"
        });
      }
    },
    checkListEventsLoop() {
      const checkListCopy = {
        POND_GUARD_DATA: "PondGuard",
        POND_MOTHER_DATA: "PondMother",
        HOURLY_FEED_DATA: "Hourly Feed",
        MANUAL_FEED_DATA: "Manual Feed",
        WATER_QUALITY_TEST_DATA: "Lab Tests",
        MEDICINES_MINERALS_DATA: "Medicines & Minerals",
        YIELD_DATA: "Yield",
        ABW_DATA: "ABW",
        INTAKE_RESPONSE_DATA: "Response Quality"
      };
      this.checkList.map((curr) => {
        this.mixPanelEventGenerator({
          eventName: "Reports - Report Type - " + checkListCopy[curr]
        });
      });
    },
    async sendemail() {
      try {
        this.emailLoading = true;
        this.$gblUAMIsHighPriorityUsersRestricted();
        if (!this.pondList.length || !this.checkList.length) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                type: "number",
                required: true,
                message: this.downloadkeys(),
                trigger: "blur"
              }
            ]
          };
        }
        await userService.sendEmailToUser({
          pond_ids: this.pondList,
          params: this.checkList,
          from_time: this.value1[0],
          to_time: this.value1[1]
        });
        this.nofm__notifySuccess({
          msgLangkey: {
            title: "Comn_email_success_msg",
            count: 0
          }
        });
        this.mixPanelEventGenerator({ eventName: "Reports - Send email" });
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.emailLoading = false;
      }
    },
    // handleCheckAllReport(val, reportType) {
    //   console.log(val, reportType);
    //   this.checkList = val ? this.reportListData : [];
    // },
    // handleCheckAllReport(val, reportType) {
    //   if (reportType === "device") {
    //     this.deviceCheckAllReports = val; // Update the flag
    //     this.cultureCheckAllReports = false; // Reset the flag
    //     this.checkList = val
    //       ? this.deviceGeneratedReportListData // All device report IDs
    //       : [];
    //   } else if (reportType === "culture") {
    //     this.cultureCheckAllReports = val; // Update the flag
    //     this.deviceCheckAllReports = false; // Reset the flag
    //     this.checkList = val
    //       ? this.cultureGeneratedReportListData // All culture report IDs
    //       : [];
    //   } else {
    //     // Handle the case for the main "All" checkbox if needed
    //     // this.checkAllReports = val;
    //     // this.checkList = val ? this.reportListData : [];
    //   }
    // },
    handleCheckAllReport(val, reportType) {
      if (reportType === "device") {
        this.deviceCheckAllReports = val;

        // Modify checkList
        if (val) {
          this.checkList = [
            ...this.checkList,
            ...this.deviceGeneratedReportListData
          ];
        } else {
          this.checkList = this.checkList.filter(
            (id) => !this.deviceGeneratedReportListData.includes(id)
          );
        }
      } else if (reportType === "culture") {
        this.cultureCheckAllReports = val;

        // Modify checkList
        if (val) {
          this.checkList = [
            ...this.checkList,
            ...this.cultureGeneratedReportListData
          ];
        } else {
          this.checkList = this.checkList.filter(
            (id) => !this.cultureGeneratedReportListData.includes(id)
          );
        }
      } else {
        // Handle the case for the main "All" checkbox if needed
      }
    },
    handleCheckAllPonds(val) {
      this.pondList = val ? this.pondListData : [];
    }
  }
};
</script>

<style lang="scss">
.reports {
  .border-bottom {
    border-bottom: 4px solid #ccc;
    /* Adjust border style as needed */
    margin: 30px 0;
  }
  .header__style {
    float: right;
    // margin-left: auto;
    @include responsiveProperty(max-width, 12rem, 12rem, 15rem);
  }
  .pond-container {
    @include responsiveProperty(height, 73vh, 77vh, 80vh);
    overflow-y: scroll;
    .no-data {
      margin-left: 46%;
      margin-top: 25%;
      opacity: 0.5;
    }
    .active {
      background-color: #0a2463 !important;
      color: #f9f9f9 !important;
    }
    .header {
      font-weight: 600;
      line-height: 2;
      margin-top: 15px;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      @include responsiveProperty(
        font-size,
        $app_font_size_1,
        $app_font_size_2,
        $app_font_size_3
      );
    }
    .title {
      @include responsiveProperty(
        font-size,
        $app_font_size_1,
        $app_font_size_2,
        $app_font_size_3
      );
      white-space: break-spaces;
    }
  }

  .report-container {
    padding-left: 30px;
    .header {
      font-weight: 600;
      line-height: 2;
      margin-top: 15px;
      margin-bottom: 15px;
      @include responsiveProperty(
        font-size,
        $app_font_size_1,
        $app_font_size_2,
        $app_font_size_3
      );
    }
    .title {
      @include responsiveProperty(
        font-size,
        $app_font_size_1,
        $app_font_size_2,
        $app_font_size_3
      );
    }
    .report-container__one {
      .report-container__one__help-text {
        margin-top: 10px;
        color: #0a2463;
        font-weight: 700;
        @include responsiveProperty(
          font-size,
          $app_font_size_1,
          $app_font_size_2,
          $app_font_size_3
        );
      }
    }
    .report-container__two {
      .report-container__two__help-text {
        margin-top: 10px;
        color: #0a2463;
        font-weight: 700;
        @include responsiveProperty(
          font-size,
          $app_font_size_1,
          $app_font_size_2,
          $app_font_size_3
        );
      }
    }
  }
  .footer-container {
    display: flex;
    justify-content: space-between;
    .info__container {
      display: flex;
      align-items: center;
      .content {
        @include responsiveProperty(
          font-size,
          $app_font_size_1,
          $app_font_size_2,
          $app_font_size_3
        );
        margin-left: 10px;
      }
    }
    .download__container {
      display: flex;
      margin-top: 6px;
      .material-icons-round {
        color: #ffffff;
        @include responsiveProperty(
          font-size,
          $app_font_size_1,
          $app_font_size_2,
          $app_font_size_3
        );
      }
      .gap__between__download__send {
        margin-right: 10px;
        .el-button {
          display: inline-flex;
        }
      }
    }

    .email-section {
      .email__container {
        border-radius: 4px;
        background-color: green;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .email__icon {
        display: flex;
        padding-left: 10px;
      }
      .email__content {
        @include responsiveProperty(
          font-size,
          $app_font_size_1,
          $app_font_size_2,
          $app_font_size_3
        );
        color: white;
        padding: 7px 15px 7px 5px;
      }
      .el-button {
        display: inline-flex;
      }
    }
  }

  .material-icons-round {
    color: #1966a1;
    margin-right: 15px;
  }

  .material-icons-outlined {
    @include responsiveProperty(
      font-size,
      $app_font_size_2,
      $app_font_size_3,
      $app_font_size_xl
    );
  }
  .el-divider--vertical {
    @include responsiveProperty(height, 73vh, 77vh, 80vh);
  }
  .el-divider--horizontal {
    margin: 0;
  }
  .el-checkbox__input {
    // .el-checkbox__inner {

    //   // width: 12px;
    //   // height: 12px;
    //   &:after {
    //     // border-color: #0a2463;
    //   }
    // }

    &.is-checked .el-checkbox__inner,
    &.is-indeterminate .el-checkbox__inner {
      background-color: #0a2463;
      border-color: #0a2463;
      // color: #fff;
    }

    &.is-checked + .el-checkbox__label {
      color: #0a2463;
    }
  }

  .el-checkbox__label {
    text-transform: capitalize;
    @include responsiveProperty(
      font-size,
      $app_font_size_v_medium,
      $app_font_size_small + 1,
      $app_font_size_1 + 2
    );
  }
}
</style>
